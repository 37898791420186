import React, {useRef} from "react";
import PropTypes from "prop-types";
import useIntersectionObserver from "@react-hook/intersection-observer";
import styles from "./LazyVideoEmbed.module.scss";

const LazyVideoEmbed = (props) => {

    const containerRef = useRef(null);
    const lockRef = useRef(false)
    const { isIntersecting } = useIntersectionObserver(containerRef);
    if (isIntersecting) {
        lockRef.current = true
    }

    return (
        <div ref={containerRef} style={{
            overflow: 'hidden',
            paddingTop: lockRef.current ? 0 : '56.25%',
            position: 'relative',
            width: '100%',
        }}>
            {lockRef.current && (
                <div>
                    <p className="m-0 mb-2">{props.video.title}</p>
                    <div className={styles.video}>
                        <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
                            <iframe
                                title={props.video.title}
                                src={props.video.type === 'vimeo' ? `https://player.vimeo.com/video/${props.video.id}?title=0&byline=0&portrait=0` : `https://www.youtube.com/embed/${props.video.id}?rel=0&modestbranding=1&controls=0`}
                                style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

};

LazyVideoEmbed.propTypes = {
    video: PropTypes.shape({
        title: PropTypes.string,
        type: PropTypes.string,
        id: PropTypes.string
    }).isRequired
};

export default LazyVideoEmbed;