import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import styles from "./Navigation.module.scss";
import iconHamburger from "../../images/icon-hamburger.svg";
import iconClose from "../../images/icon-close.svg";
import iconMusic from "../../images/icon-nav-music.svg";
import iconVideo from "../../images/icon-nav-video.svg";
import iconBio from "../../images/icon-nav-bio.svg";
import iconContact from "../../images/icon-nav-contact.svg";
import { Row, Col } from "react-bootstrap";
import { gsap } from "gsap";
import SocialIcons from "../../components/SocialIcons/SocialIcons";
import { useMedia } from "react-use";

const Navigation = (props) => {

    const isMobile = useMedia('(max-width: 992px)');
    const [isOpen, setIsOpen] = useState(false);
    const overlayRef = useRef(null);
    const navItems = useRef(null);
    let tl = gsap.timeline();

    const toggleNav = () => {
        tl.clear();
        if (!isOpen) {
            tl.set(overlayRef.current, {display: 'block'});
            tl.fromTo(overlayRef.current, {x: '100%'}, {x: '0', duration: 0.4, ease: 'power4.out'});
            if (isMobile) {
                tl.fromTo(navItems.current.querySelectorAll('a'), {x: '100px', opacity: 0}, {x: 0, opacity: 1, duration: 0.3, stagger: 0.1}, '-=0.3');
            }
            setIsOpen(true);
        } else {
            tl.to(overlayRef.current, {x: '100%', duration: 0.4, ease: 'power4.out'});
            tl.set(overlayRef.current, {display: 'none'});
            setIsOpen(false);
        }
    };

    const hideNav = () => {
        gsap.set(overlayRef.current, {display: 'none'});
        setIsOpen(false);
    };

    const NavHeader = props => {
        return (
            <Row className={styles.inner}>
                <Col xs={9}>
                    <Link to="/" className={styles.logo} onClick={hideNav}>
                        David Chambers
                    </Link>
                </Col>
                <Col className={styles.right}>
                    <div onClick={toggleNav}>
                        <button>
                            <img src={props.icon} alt="Nav icon" />
                        </button>
                    </div>
                </Col>
            </Row>
        );
    };

    return (
        <React.Fragment>
            <nav className={styles.container}>
                <div className="d-lg-none">
                    <NavHeader icon={iconHamburger} />
                    <div ref={overlayRef} className={styles.overlay}>
                        <NavHeader icon={iconClose} />
                        <div ref={navItems} className={styles.navItems}>
                            <Link to="/music" className={styles.navItem} onClick={hideNav}>
                                <img src={iconMusic} alt="Music" className="me-5" />
                                <span>Music</span>
                            </Link>
                            <Link to="/video" className={styles.navItem} onClick={hideNav}>
                                <img src={iconVideo} alt="Video" className="me-5" />
                                <span>Video</span>
                            </Link>
                            <Link to="/biography" className={styles.navItem} onClick={hideNav}>
                                <img src={iconBio} alt="Biography" className="me-5" />
                                <span>Biography</span>
                            </Link>
                            <Link to="/contact" className={styles.navItem} onClick={hideNav}>
                                <img src={iconContact} alt="Contact" className="me-5" />
                                <span>Contact</span>
                            </Link>
                        </div>

                        <div className={styles.icons}>
                            <SocialIcons />
                        </div>
                    </div>
                </div>
                <div className="d-none d-lg-block">
                    <ul className={styles.inner}>
                        <li>
                            <Link to="/music">Music</Link>
                        </li>
                        <li>
                            <Link to="/video">Video</Link>
                        </li>
                        <li className={styles.logo}>
                            <Link to="/">David<br />Chambers</Link>
                        </li>
                        <li>
                            <Link to="/biography">Biography</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </React.Fragment>
    );

};

export default Navigation;