import React from "react";
import { Routes, Route } from "react-router-dom";
import './App.scss';
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import Home from "../../pages/Home/Home";
import Music from "../../pages/Music/Music";
import Navigation from "../Navigation/Navigation";
import Video from "../../pages/Video/Video";
import Biography from "../../pages/Biography/Biography";
import Contact from "../../pages/Contact/Contact";

const App = () => {

    return (
        <div className="App" id="App">
            <Navigation />
            <ErrorBoundary>
                <Routes>
                    <Route index element={<Home />} />
                    <Route exact path="music" element={<Music />} />
                    <Route exact path="video" element={<Video />} />
                    <Route exact path="biography" element={<Biography />} />
                    <Route exact path="contact" element={<Contact />} />
                </Routes>
            </ErrorBoundary>
        </div>
    );

}

export default App;
