import React from "react";
import PropTypes from "prop-types";
import styles from "./SocialIcons.module.scss";

const SocialIcons = (props) => {

    //todo: Update the URLs

    return (
        <div className={styles.container}>
            <a href="https://www.facebook.com/DavidChMusic/" target="_blank" rel="noreferrer" className={styles.sprite} style={{backgroundPosition: '0, 0'}}>
                <span>Facebook</span>
            </a>
            <a href="https://www.youtube.com/channel/UCVAhDxKv4q3n0ogVqP38aXw" target="_blank" rel="noreferrer"  className={styles.sprite} style={{backgroundPosition: '-43px, 0'}}>
                <span>YouTube</span>
            </a>
            <a href="https://soundcloud.com/david_chambers_music" target="_blank" rel="noreferrer"  className={styles.sprite} style={{backgroundPosition: '-85px, 0', width: '53px'}}>
                <span>Soundcloud</span>
            </a>
            <a href="https://open.spotify.com/artist/7wQIyqP6dsVY4Tf7rBXM8b" target="_blank" rel="noreferrer"  className={styles.sprite} style={{backgroundPosition: '-150px, 0'}}>
                <span>Spotify</span>
            </a>
            <a href="https://itunes.apple.com/au/artist/david-chambers/id442118458" target="_blank" rel="noreferrer"  className={styles.sprite} style={{backgroundPosition: '-194px, 0', width: '34px'}}>
                <span>iTunes</span>
            </a>
            <a href="https://vimeo.com/davidchambersmusic" target="_blank" rel="noreferrer"  className={styles.sprite} style={{backgroundPosition: '-242px, 0'}}>
                <span>Vimeo</span>
            </a>
            <a href="https://www.linkedin.com/in/david-chambers-music/" target="_blank" rel="noreferrer"  className={styles.sprite} style={{backgroundPosition: '-289px, 0'}}>
                <span>Linkedin</span>
            </a>
            <a href="https://twitter.com/davidch_music" target="_blank" rel="noreferrer"  className={styles.sprite} style={{backgroundPosition: '-335px, 0'}}>
                <span>Twitter</span>
            </a>
            <a href="https://www.instagram.com/david_chambers_music/" target="_blank" rel="noreferrer"  className={styles.sprite} style={{backgroundPosition: '-380px, 0'}}>
                <span>Instagram</span>
            </a>
            {!props.hideTitle ?
                <p className={styles.title}>
                    LISTEN AND FOLLOW
                </p>
            : ''}
        </div>
    );

};

SocialIcons.propTypes = {
    hideTitle: PropTypes.bool
}

export default SocialIcons;