import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./ButtonOutline.module.scss";

const ButtonOutline = (props) => {

    if (props.to.includes('http')) {
        return (
            <a href={props.to} className={styles.button} style={props.style} rel="noreferrer" target="_blank">
                {props.children}
            </a>
        );
    }

    return (
        <Link to={props.to} className={styles.button} style={props.style}>
            {props.children}
        </Link>
    );

};

ButtonOutline.propTypes = {
    to: PropTypes.string,
    style: PropTypes.object
};

ButtonOutline.defaultProps = {
    style: {}
};

export default ButtonOutline;