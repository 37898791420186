import React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.scss";
import SocialIcons from "../../components/SocialIcons/SocialIcons";
import { Row, Col } from "react-bootstrap";

const Footer = (props) => {

    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <div className={styles.nav}>
                    <Link to="/music">Music</Link>
                    <Link to="/video">Video</Link>
                    <Link to="/biography">Biography</Link>
                    <Link to="/music">Contact</Link>
                </div>
                <SocialIcons hideTitle={true} />
                <div className={styles.links}>
                    <Row>
                        <Col lg={7} className="mb-5 mb-lg-0">
                            <h3>FRIENDS</h3>
                            <Row>
                                <Col xs={6} lg={3}>
                                    <a href="http://www.andrewgoldie.com/" target="_blank" rel="noreferrer">ANDREW GOLDIE</a><br />
                                    <a href="https://www.interestingshit.com/author/ithornton/" target="_blank" rel="noreferrer">CLICK IF YOU DARE</a><br />
                                    <a href="http://dennyball.tripod.com/" target="_blank" rel="noreferrer">DENNY BALL</a>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <a href="http://cammackellar.com/" target="_blank" rel="noreferrer">CAM MACKELLAR</a><br />
                                    <a href="http://evanpapageorgiou.com/" target="_blank" rel="noreferrer">EVAN PAPAGEORGIOU</a><br />
                                    <a href="https://www.amyfreemanmusic.com/" target="_blank" rel="noreferrer">AMY FREEMAN</a>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <a href="https://www.vevo.com/" target="_blank" rel="noreferrer">VEVO</a><br />
                                    <a href="https://hughwade.com/" target="_blank" rel="noreferrer">HUGH WADE</a>
                                    <a href="https://josh.kiwi/" target="_blank" rel="noreferrer">JOSH WARNER</a>
                                </Col>
                                <Col xs={6} lg={3}>
                                    <a href="http://www.nickfranklin.net/" target="_blank" rel="noreferrer">NICK FRANKLIN</a><br />
                                    <a href="https://www.soundcloud.com/kokoroller" target="_blank" rel="noreferrer">KOKOROLLER</a>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={{span: 4, offset: 1}}>
                            <h3>LINKS</h3>
                            <a href="http://www.sofiafitzpatrick.com.au/" target="_blank" rel="noreferrer">SKULL JEWELLERY BY SOFIA FITZPATRICK</a><br />
                            <a href="https://chstudio.com.au/" target="_blank" rel="noreferrer">CATHERINE HOURIHAN PHOTOGRAPHY</a><br />
                            <a href="http://www.peterholz.com/" target="_blank" rel="noreferrer">PETER HOLZ</a>
                        </Col>
                    </Row>
                </div>

                <div className={styles.josh}>
                    Website by <a href="https://josh.kiwi/" target="_blank" rel="noreferrer">josh.kiwi</a>
                </div>
            </div>
        </div>
    );

};

export default Footer;