import React, {useEffect, useRef, useState} from "react";
import Footer from "../../layout/Footer/Footer";
import daveBio from "../../images/david-chambers-bio.jpg";
import daveBioLarge from "../../images/david-chambers-bio-large.jpg";
import styles from "./Biography.module.scss";
import HeadingWithReflection from "../../components/HeadingWithReflection/HeadingWithReflection";
import LatestRelease from "../../components/LatestRelease/LatestRelease";
import ButtonOutline from "../../components/ButtonOutline/ButtonOutline";
import {gsap} from "gsap";

const Biography = (props) => {

    const [isHidden, setIsHidden] = useState(true);
    const container = useRef(null);

    useEffect(() => {
        gsap.to(container.current, {opacity: 1, duration: 0.5});
    }, []);

    const handleClick = event => {
        event.preventDefault();
        setIsHidden(false);
    };

    return (
        <div ref={container} style={{opacity: 0}}>
            <div className={styles.inner}>
                <div className={styles.desktopHeading}>
                    <HeadingWithReflection title="Biography" />
                </div>
                <div className="d-flex">
                    <div className={styles.desktopCopy}>
                        <p>David Chambers started his music career in London playing in clubs by night and holding down various ad hoc jobs including working on spotlights at the London Palladium for Tommy Steele’s long running “Singin’ In The Rain”, and selling ‘swag’ merchandise outside of music venues across the U.K and Europe. Opportunities to work for London music agents and promoters eventuated in international concert tour management and working with international music promoters for many artists including Paul McCartney, The Rolling Stones, David Bowie, Pink Floyd, U2, Bruce Springsteen, Elton John, Deborah Harry, Iggy Pop and Nirvana.</p>
                        <p>David Chambers now based in Sydney has continued his musical ventures with the recording of both albums and singles in the recent years: Bouquet of Barbed Wire (2011), In The Next Life (2014), Resolution (2017), Bronte Road (2019), and single Sweet Deception released with the remastered version of Bouquet of Barbed Wire in 2020. Collaborating with producer singer-songwriter Cam Mackellar for the first two albums and self producing the latest release, David Chambers has been supported by a range of talented singers and players: Lisa Callingham, Madeleine Slattery, Kevin Elsey, Dave Wray, Brendan J. Beame, Josh Ahearn, Paul Jackson, Denny Ball, Luke Gerber, Anatoli Torjinski, Janine Simmel and long standing collaborator Ronnie “Wood” Harvey.</p>
                        <p>Recording recently at Sony Music Studios Sydney, David Chambers is continuing work on his next album of self penned songs.</p>
                    </div>
                    <div className={`${styles.image} d-none d-lg-block`}>
                        <img src={daveBioLarge} alt="David Chambers Musician" />
                        <div className={styles.excerpt}>
                            Castaway, Hannie, Bronte Road, Life, Wanderlust, Sweet Deception, In The Next Life, Resolution, Catch A Smile, Valentine Blue, El Paso, Breathe, Splash, Exposure and more
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-lg-none">
                <div className={styles.image}>
                    <img src={daveBio} alt="David Chambers Musician" />
                    <div className={styles.header}>
                        <h2 className="m-0">BIOGRAPHY</h2>
                    </div>
                </div>
                <div className={styles.copy}>
                    <p>David Chambers started his music career in London playing in clubs by night and holding down various ad hoc jobs including working on spotlights at the London Palladium for Tommy Steele’s long running “Singin’ In The Rain”, and selling ‘swag’ merchandise outside of music venues across the U.K and Europe.</p>
                    {isHidden ?
                        <p className="text-right"><a href="/" onClick={handleClick}>Read more</a></p>
                        :
                        <React.Fragment>
                            <p>Opportunities to work for London music agents and promoters eventuated in international concert tour management and working with international music promoters for many artists including Paul McCartney, The Rolling Stones, David Bowie, Pink Floyd, U2, Bruce Springsteen, Elton John, Deborah Harry, Iggy Pop and Nirvana.</p>
                            <p>David Chambers now based in Sydney has continued his musical ventures with the recording of both albums and singles in the recent years: Bouquet of Barbed Wire (2011), In The Next Life (2014), Resolution (2017), Bronte Road (2019), and single Sweet Deception released with the remastered version of Bouquet of Barbed Wire in 2020. Collaborating with producer singer-songwriter Cam Mackellar for the first two albums and self producing the latest release, David Chambers has been supported by a range of talented singers and players: Lisa Callingham, Madeleine Slattery, Kevin Elsey, Dave Wray, Brendan J. Beame, Josh Ahearn, Paul Jackson, Denny Ball, Luke Gerber, Anatoli Torjinski, Janine Simmel and long standing collaborator Ronnie “Wood” Harvey.</p>
                            <p>Recording recently at Sony Music Studios Sydney, David Chambers is continuing work on his next album of self penned songs.</p>
                        </React.Fragment>
                    }
                </div>
            </div>
            <LatestRelease />

            <div className={styles.letsChat}>
                <div className="mb-5">
                    <HeadingWithReflection title="Looking for something?" isLarge={false} />
                </div>
                <ButtonOutline to="/contact">Lets chat</ButtonOutline>
            </div>

            <Footer />
        </div>
    );

};

export default Biography;