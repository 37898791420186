import React from "react";
import styles from "./LatestRelease.module.scss";
import albumCover from "../../images/david-chambers-breathe.jpg";
import HeadingWithReflection from "../HeadingWithReflection/HeadingWithReflection";
import wave from "../../images/music-wave.svg";
import SocialIcons from "../SocialIcons/SocialIcons";
import spotify from "../../images/spotify.png";

const LatestRelease = (props) => {

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.container}>
                <h2 className="text-center d-lg-none">LATEST RELEASE</h2>
                <div className="text-center d-none d-lg-block">
                    <HeadingWithReflection title="Latest Release" />
                </div>
                <div className={styles.details}>
                    <div className={styles.cover}>
                        <img src={albumCover} alt="David Chambers Breathe" />
                    </div>
                    <div className="text-center mt-3">
                        <p className="m-0 bold font-medium">Breathe</p>
                        <p className="m-0 mb-4 font-small">David Chambers <span className="font-tiny">&bull; 2020 &bull; 10 songs</span></p>
                        <a href="https://open.spotify.com/album/09iTkvlLPa5INXLP0pPhkR" target="_blank" rel="noreferrer" className={styles.btn}>
                            Listen on <img src={spotify} alt="Spotify" />
                        </a>
                    </div>
                </div>
            </div>
            <div className={styles.listen}>
                <img src={wave} alt="David Chambers Music" className={styles.wave} />
                <SocialIcons hideTitle={false} />
            </div>
        </div>
    );

};

LatestRelease.propTypes = {};

export default LatestRelease;