import React from "react";
import PropTypes from "prop-types";
import styles from "./BottomSection.module.scss";
import musicWave from "../../images/music-wave.svg";
import ButtonOutline from "../../components/ButtonOutline/ButtonOutline";
import SocialIcons from "../../components/SocialIcons/SocialIcons";

const BottomSection = (props) => {

    return (
        <div className={styles.container}>
            <div className="d-none d-lg-block">
                <SocialIcons />
            </div>
            {!props.hideContactBtn ?
                <div className={styles.cta}>
                    <ButtonOutline to="/contact">Get in touch</ButtonOutline>
                    <img src={musicWave} alt="Music Wave" className={styles.waveLeft} />
                    <img src={musicWave} alt="Music Wave" className={styles.waveRight} />
                </div>
            : ''}
            <div className="d-lg-none">
                <SocialIcons />
            </div>
        </div>
    );

};

BottomSection.propTypes = {
    hideContactBtn: PropTypes.bool
};

export default BottomSection;