import React, { useEffect, useRef } from "react";
import styles from "./Home.module.scss";
import daveProfile from "../../images/david-chambers-profile-cropped.jpg";
import davePiano from "../../images/david-chambers-piano.jpg";
import daveRoadCropped from "../../images/david-chambers-crossing-road-cropped.jpg";
import HeadingWithReflection from "../../components/HeadingWithReflection/HeadingWithReflection";
import ButtonOutline from "../../components/ButtonOutline/ButtonOutline";
import Footer from "../../layout/Footer/Footer";
import BottomSection from "../../layout/BottomSection/BottomSection";
import davePianoLeft from "../../images/david-chambers-piano-left.jpeg";
import daveProfileLarge from "../../images/david-chambers-profile.jpg";
import daveStudio from "../../images/david-chambers-studio.jpg";
import daveRoad from "../../images/david-chambers-crossing-road.jpg";
import daveMic from "../../images/david-chambers-mic.png";
import { gsap } from "gsap";
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";

const Home = (props) => {

    const container = useRef(null);

    useEffect(() => {
        gsap.to(container.current, {opacity: 1, duration: 0.5});
    }, []);

    return (
        <div ref={container} style={{opacity: 0}}>
            <div className={styles.container}>
                <div className="d-lg-none">
                    <img src={daveProfile} className={styles.image1} alt="David Chambers Music" />
                    <div className="mt-3 text-center">
                        <HeadingWithReflection title="David<br />Chambers" isLarge={true} />
                    </div>
                    <div className="text-center mt-4">
                        <ButtonOutline to="/music">Explore music</ButtonOutline>
                    </div>
                    <img src={davePiano} className={styles.image2} alt="David Chambers Piano" />
                    <img src={daveRoadCropped} className={styles.image3} alt="David Chambers Bronte" />
                </div>
                <div className="d-none d-lg-block">
                    <MouseParallaxContainer containerStyles={{position: 'unset', overflow: 'visible'}}>
                        <MouseParallaxChild factorX={0.02} factorY={0.01}>
                            <div className={styles.imageTopLeft}>
                                <img src={davePianoLeft} alt="David Chambers Piano" />
                                <div className={styles.bgTopLeft} />
                            </div>
                        </MouseParallaxChild>
                        <MouseParallaxChild factorX={0.03} factorY={0.02}>
                            <div className={styles.imageTopRight}>
                                <img src={daveProfileLarge} alt="David Chambers Musician" />
                                <div className={styles.bgTopRight} />
                            </div>
                        </MouseParallaxChild>
                        <MouseParallaxChild factorX={0.01} factorY={0.01}>
                            <div className={styles.imageCenterLeft}>
                                <img src={daveStudio} alt="David Chambers In Studio" />
                                <div className={styles.bgCenterLeft} />
                            </div>
                        </MouseParallaxChild>
                        <MouseParallaxChild factorX={0.02} factorY={0.03}>
                            <div className={styles.imageBottomLeft}>
                                <img src={daveRoad} alt="David Chambers Bronte" />
                                <div className={styles.bgBottomLeft} />
                            </div>
                        </MouseParallaxChild>
                        <MouseParallaxChild factorX={0.01} factorY={0.01}>
                            <div className={styles.imageBottomRight}>
                                <img src={daveMic} alt="David Chambers Musician" />
                                <div className={styles.bgBottomRight} />
                            </div>
                        </MouseParallaxChild>
                        <div style={{zIndex: 2, position: 'relative'}}>
                            <MouseParallaxContainer containerStyles={{position: 'unset', overflow: 'visible'}}>
                                <MouseParallaxChild factorX={0.03} factorY={0.04}>
                                    <div className={styles.heading}>
                                        <HeadingWithReflection title="David<br />Chambers" isLarge={true} />
                                    </div>
                                </MouseParallaxChild>
                            </MouseParallaxContainer>
                            <div className="text-center">
                                <ButtonOutline to="/music">Explore music</ButtonOutline>
                            </div>
                        </div>
                    </MouseParallaxContainer>
                </div>
            </div>
            <BottomSection />
            <Footer />
        </div>
    );

};

export default Home;