import React, { useEffect, useRef, useState } from "react";
import BottomSection from "../../layout/BottomSection/BottomSection";
import Footer from "../../layout/Footer/Footer";
import { gsap } from "gsap";
import HeadingWithReflection from "../../components/HeadingWithReflection/HeadingWithReflection";
import styles from "./Music.module.scss";
import Obfuscate from "react-obfuscate";
import data from "../../data/music.json";
import Vimeo from "react-vimeo-embed";
import YouTube from "react-youtube";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { useMedia } from "react-use";
import { CarouselProvider, Slider, Slide, DotGroup, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import spotify from "../../images/spotify.png";

const Music = (props) => {

    const [currentTrack, setCurrentTrack] = useState({});
    const container = useRef(null);
    const isMobile = useMedia('(max-width: 992px)');
    const isTablet = useMedia('(min-width: 768px) and (max-width: 1024px)');
    const isSmallScreen = useMedia('(min-width: 922px) and (max-width: 1500px)');

    useEffect(() => {
        gsap.to(container.current, {opacity: 1, duration: 0.5});
    }, []);

    const playTrack = track => {
        setCurrentTrack(track);
    };

    const youtubeOptions = {
        width: '100%',
        playerVars: {
            rel: 0,
            modestbranding: 1
        }
    };

    const Videos = () => {
        return data.promos.map((video, key) => {
            return (
                <div key={key} className={styles.video}>
                    <p className="m-0 mb-2">{video.title}</p>
                    {video.type === 'vimeo' ?
                        <Vimeo video={video.id} responsive loading="lazy" showTitle={false} />
                        :
                        <YouTube videoId={video.id} opts={youtubeOptions} title={video.title} />
                    }
                </div>
            );
        });
    }

    return (
        <div ref={container} className={styles.container} style={{opacity: 0}}>
            <div className={styles.inner}>
                <div className="px-4">
                    <div className={styles.title}>
                        <div className="w-50">
                            <HeadingWithReflection title="Music to License" />
                        </div>
                        <div className="text-center flex-grow-1">
                            <p className="m-0">DCM music available for licensing.</p>
                            <p className="m-0"><Obfuscate email="    licensing@davidchambersmusic.com" /></p>
                        </div>
                    </div>
                    <div className="d-lg-none mb-5">
                        <h1 className="d-lg-none m-0 mb-2">MUSIC TO LICENSE</h1>
                        <p className="m-0">DCM music available for licensing.</p>
                        <p className="m-0"><Obfuscate email="    licensing@davidchambersmusic.com" /></p>
                    </div>
                </div>
                <div className={styles.promos}>
                    <h2 className="mb-4 px-4 px-lg-0">LICENSING CLIPS</h2>
                    <div className="d-none d-lg-flex flex-wrap">
                        <Videos />
                    </div>
                    <CarouselProvider
                        naturalSlideWidth={90}
                        naturalSlideHeight={isTablet ? 50 : isMobile ? 70 : 85}
                        totalSlides={data.promos.length}
                        visibleSlides={1}
                        className={`d-lg-none mb-5 ${styles.videoCarousel}`}
                    >
                        <div className={styles.btnBack}>
                            <ButtonBack />
                        </div>
                        <Slider>
                            {data.promos.map((video, key) => {
                                return (
                                    <Slide index={key} key={key}>
                                        <div className={styles.video}>
                                            <p className="m-0 mb-2">{video.title}</p>
                                            {video.type === 'vimeo' ?
                                                <Vimeo video={video.id} responsive loading="lazy" showTitle={false} />
                                                :
                                                <YouTube videoId={video.id} opts={{width: '100%', loading: 'lazy'}} title={video.title} />
                                            }
                                        </div>
                                    </Slide>
                                );
                            })}
                        </Slider>
                        <div className={styles.btnNext}>
                            <ButtonNext />
                        </div>
                        <div className="text-center">
                            <DotGroup />
                        </div>
                    </CarouselProvider>

                </div>
                <div>
                    <h2 className="mb-4 px-4 px-lg-0">MUSIC</h2>
                    <div className={styles.music}>
                        <table cellPadding="18" width="100%">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    {/*<td>&nbsp;</td>*/}
                                    <td>SONG</td>
                                    <td>GENRE</td>
                                    <td>MOOD</td>
                                    <td>&nbsp;</td>
                                </tr>
                            </thead>
                            <tbody>
                            {data.music.map((track, key) => {
                                return (
                                    <tr key={key} className={`${styles.song} ${track.title === currentTrack.title ? styles.active : ''}`} onClick={() => playTrack(track)}>
                                        <td>{key+1}</td>
                                        {/*<td>[ART]</td>*/}
                                        <td>{track.title}</td>
                                        <td>{track.genre}</td>
                                        <td>{track.mood}</td>
                                        <td>
                                            <Obfuscate
                                                email="    licensing@davidchambersmusic.com"
                                                headers={{subject: `Licensing Enquiry - ${track.title}`}}
                                                target="_blank"
                                            >Enquire</Obfuscate>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                    <div className={styles.player}>
                        <AudioPlayer
                            autoPlay
                            controls
                            src={currentTrack.url}
                            onPlay={e => console.log("onPlay")}
                        />
                        <div className={styles.playerTitle}>
                            {currentTrack.title}
                        </div>
                    </div>
                </div>
                <div className={styles.albums}>
                    <h2 className="mb-4 px-4 px-lg-0">ALBUMS/SINGLES</h2>
                    <CarouselProvider
                        naturalSlideWidth={100}
                        naturalSlideHeight={isTablet ? 50 : isMobile ? 90 : 160}
                        totalSlides={data.albums.length}
                        visibleSlides={isMobile ? 1 : 5}
                    >
                        <div className={styles.btnBack}>
                            <ButtonBack />
                        </div>
                        <Slider>
                            {data.albums.map((album, key) => {
                                return (
                                    <Slide key={key} index={key}>
                                        <div className={styles.album}>
                                            <img src={`/images/albums/${album.image}`} alt={album.title} className="mb-3" />
                                            <p className={styles.albumTitle}>{album.title}</p>
                                            <p className="m-0 bold font-small">
                                                David Chambers<span className={styles.subTitle}>{isSmallScreen ? <br /> : ' • '}{album.subTitle}</span>
                                            </p>
                                            <a href={album.url} target="_blank" rel="noreferrer" className={styles.listen}>
                                                Listen on <img src={spotify} alt="Spotify" />
                                            </a>
                                        </div>
                                    </Slide>
                                );
                            })}
                        </Slider>
                        <div className={styles.btnNext}>
                            <ButtonNext />
                        </div>
                        <div className="d-lg-none text-center">
                            <DotGroup />
                        </div>
                    </CarouselProvider>
                </div>
            </div>

            <BottomSection />
            <Footer />
        </div>
    );

};

export default Music;