import React from "react";
import PropTypes from "prop-types";
import styles from "./HeadingWithReflection.module.scss";

const HeadingWithReflection = (props) => {

    const Duplicates = () => {
        let duplicates = [];
        for (let i = 1; i <= 6; i++) {
            duplicates.push(<span key={i} className={`${styles.reflection} ${props.isLarge ? styles.large : ''}`} dangerouslySetInnerHTML={{__html: props.title}} />);
        }
        return duplicates;
    };

    return (
        <div className={styles.container}>
            <h1 className={`${styles.title} ${props.isLarge ? styles.large : ''}`} dangerouslySetInnerHTML={{__html: props.title}} />
            {Duplicates()}
        </div>
    );

};

HeadingWithReflection.propTypes = {
    title: PropTypes.string.isRequired,
    isLarge: PropTypes.bool
};

export default HeadingWithReflection;