import React, {useEffect, useRef} from "react";
import BottomSection from "../../layout/BottomSection/BottomSection";
import Footer from "../../layout/Footer/Footer";
import styles from "./Video.module.scss";
import videos from "../../data/videos.json";
import HeadingWithReflection from "../../components/HeadingWithReflection/HeadingWithReflection";
import { gsap } from "gsap";
import LazyVideoEmbed from "../../components/LazyVideoEmbed/LazyVideoEmbed";

const Video = (props) => {

    const containerRef = useRef(null);

    useEffect(() => {
        gsap.to(containerRef.current, {opacity: 1, duration: 0.5});
    }, []);

    return (
        <div ref={containerRef} style={{opacity: 0}}>
            <div className={styles.inner}>
                <div className={styles.title}>
                    <HeadingWithReflection title="Browse videos..." />
                </div>
                <h1 className="d-lg-none mb-3 px-3">BROWSE VIDEOS</h1>
                <div className={styles.container}>
                    <h2 className="mb-4 d-none d-lg-block">VIDEOS</h2>
                    {videos.map((video, key) => {
                        return (
                            <div key={key} className={styles.video}>
                                <LazyVideoEmbed key={key} video={video} />
                            </div>
                        );
                    })}
                </div>
            </div>
            <BottomSection />
            <Footer />
        </div>
    );

};

export default Video;