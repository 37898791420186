import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import BottomSection from "../../layout/BottomSection/BottomSection";
import Footer from "../../layout/Footer/Footer";
import { gsap } from "gsap";
import styles from "./Contact.module.scss";
import HeadingWithReflection from "../../components/HeadingWithReflection/HeadingWithReflection";
import Obfuscate from "react-obfuscate";
import loading from "../../images/loading.svg";
import iconContact from "../../images/icon-nav-contact.svg";
import * as Sentry from "@sentry/react";

const Contact = (props) => {

    const container = useRef(null);
    const button = useRef(null);
    const loader = useRef(null);
    const formRef = useRef(null);
    const success = useRef(null);
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [hasError, setHasError] = useState(false);
    const tl = gsap.timeline();

    useEffect(() => {
        gsap.to(container.current, {opacity: 1, duration: 0.5});
    }, []);

    const onSubmit = data => {
        setHasError(false);
        animateLoaderIn();

        postData('/.netlify/functions/send-emails', data)
            .then(response => {
                //todo: Should I log these as well just for a record
                animateFormOut();
                window.gtag('event', 'generate_lead', {
                    currency: 'AUD',
                    value: 0.00
                });
            })
            .catch(error => {
                Sentry.withScope((scope) => {
                    scope.setExtras({
                        data: JSON.stringify(data),
                        error: error
                    });
                    Sentry.captureMessage('An error occurred while trying to send an email');
                });
                animateLoaderOut();
                setHasError(true);
            });
    };

    const animateLoaderIn = () => {
        tl.clear();
        tl.to(button.current, {opacity: 0, duration: 0.15, ease: 'power4.out'});
        tl.set(button.current, {display: 'none'});
        tl.set(loader.current, {display: 'block', opacity: 0});
        tl.to(loader.current, {opacity: 1, duration: 0.25, ease: 'power4.out'});
    };

    const animateLoaderOut = () => {
        tl.clear();
        tl.set(loader.current, {display: 'none'});
        tl.set(button.current, {display: 'block', opacity: 1});
    };

    const animateFormOut = () => {
        const tl = gsap.timeline();
        tl.to(formRef.current, {opacity: 0, duration: 0.2, ease: 'power4.out'});
        tl.set(formRef.current, {display: 'none'});
        tl.set(success.current, {display: 'block', opacity: 0});
        tl.to(success.current, {opacity: 1});
    };

    const postData = (url, data = {}) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: url,
                data: data,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    reject(response);
                });
        });
    };

    const Error = message => {
        return <div className={`alert alert-danger mt-2 ${styles.alert}`} dangerouslySetInnerHTML={{__html: message}} />;
    };

    return (
        <div ref={container} style={{opacity: 0}}>
            <div className={styles.inner}>
                <div className="px-4">
                    <div className={styles.title}>
                        <div className="w-50">
                            <HeadingWithReflection title="Contact" />
                        </div>
                    </div>
                    <div className={styles.bg}>
                        <div className="d-lg-none my-5 text-center">
                            <div className="d-flex justify-content-center align-items-center mb-4">
                                <h1 className="d-lg-none m-0 me-3">CONTACT</h1>
                                <img src={iconContact} alt="Lets chat" />
                            </div>
                            <p className="font-smaller">Say hello... anytime is paradise... with you.</p>
                            <p className="font-smaller"><Obfuscate email="licensing@davidchambersmusic.com" /></p>
                        </div>
                        <div className="d-none d-lg-block mb-5 text-center">
                            <div className="d-flex justify-content-center align-items-center mb-4">
                                <h1 className="m-0 mb-2 me-4">LET'S CHAT</h1>
                                <img src={iconContact} alt="Lets chat" />
                            </div>
                            <p className="font-medium">Say hello... anytime is paradise... with you.</p>
                            <p className="font-medium"><Obfuscate email="licensing@davidchambersmusic.com" /></p>
                        </div>
                        <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                            <input type="hidden" name="form-name" value="contact" />
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className={styles.input}>
                                        <label>Name</label>
                                        <input defaultValue="" {...register('name', {required: true})} placeholder="Enter your name" />
                                        {errors.name && Error('Your name is required')}
                                    </div>
                                    <div className={styles.input}>
                                        <label>Email address</label>
                                        <input defaultValue="" {...register('email', {required: true, pattern: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i})} placeholder="Enter your email address" />
                                        {errors.email && Error('A valid email address is required')}
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className={styles.input}>
                                        <label>Message</label>
                                        <textarea defaultValue="" {...register('message', {required: true})} placeholder="Enter your message here" />
                                        {errors.message && Error('A message is required')}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div ref={loader} className={styles.loader}>
                                        <img src={loading} alt="loading..." />
                                    </div>
                                    <button ref={button} onClick={handleSubmit(onSubmit)} className={styles.btn}>Send message</button>
                                    {Object.keys(errors).length > 0 && Error('Please fix the errors above before submitting.')}
                                    {hasError && Error('Oops! Something went wrong and your message was not sent.<br />Please try again or email me directly at     licensing@davidchambersmusic.com')}
                                </div>
                            </div>
                        </form>
                        <div ref={success} style={{display: 'none'}}>
                            <div className={`alert alert-success mt-2 ${styles.alert}`}>Cheers! Your message was successfully sent. <br />I will get back to you as soon as possible.</div>
                        </div>
                    </div>
                </div>
            </div>

            <BottomSection hideContactBtn={true} />
            <Footer />
        </div>
    );

};

export default Contact;